import { createContext } from 'react';
import { User } from '../models/User';

export interface IUserContext {
  user: User | null,
  setUser: (user: User | null) => void,
  isLoading: boolean
}

//set initial value of user to null (pre-login)
export const UserContext = createContext<IUserContext>({
  user: null,
  setUser: user => null,
  isLoading: false,
});