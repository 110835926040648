import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export default function Loading() {
  return (
    <Container>
      <Content>
        <LoadingOutlined color={'#FFFFFF'} />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #001956;
  opacity: .3;
  top: 0;
  left: 0;
  position: fixed;
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: #FFFFFF;
  font-size: 5rem;
`;