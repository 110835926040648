import { useState } from 'react';
import axios from 'axios';

export default function useValidateToken() {
  const [success, setSuccess] = useState<boolean | null>(null);
  const [error, setError] = useState<{ [prop: string]: any; } | null>(null);

  const validate = async (token: string) => {

    return axios.post<{ confirmed: boolean }>(`/api/auth/confirm-email/${token}`)
      .then((res) => setSuccess(res.data.confirmed))
      .catch((err) => {
        setSuccess(false);
        setError(err.response.data?.error);
      });
  };

  return {
    success,
    error,
    validate,
  };
}